.change-password-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    margin-top:50px;
  }
  
  .change-password-form h2 {
    margin-bottom: 20px;
  }
  
  .change-password-form div {
    margin-bottom: 15px;
  }
  
  .change-password-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .change-password-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .change-password-form button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .change-password-form button:hover {
    background-color: #45a049;
  }