.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__day--selected {
  background-color: #5a67d8 !important;
  color: #fff !important;
}

.react-datepicker__day--in-range {
  background-color: #5a67d8 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #7986cb !important;
  color: #fff !important;
}



.carousel-wrapper {
  position: relative;
  overflow: hidden;
}


.carousel-container {
  width: 100%;
  height: 400px;
  max-height: 70vh;
  overflow: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; 
}

.carousel-item {
  scroll-snap-align: start;
  flex: 0 0 auto;
  width: calc(100% - 20px); 
  max-width: 300px; 
}


.react-datepicker__triangle::before {
  margin-left: -7px;
  top: -10px;
}
.react-datepicker__triangle::after {
  margin-left: -7px;
  top: -10px;
}